import React from 'react';

import styled from 'styled-components/macro';
import { omit } from 'ramda';

import { Link, graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import Layout from 'src/components/Layout';
import SEO from 'src/components/Seo';
import StyledContentWrapper from 'src/components/StyledContentWrapper';
import { H1, H2, P, HeroSubtext, basePStyles } from 'src/components/Styled';

import * as colors from 'src/constants/colors';
import {
  CONTENT_HZ_PADDING_PX,
  MOBILE_CONTENT_HZ_PADDING_PX,
  TABLET_SCREEN_WIDTH_PX,
  HERO_HEIGHTS_BY_PAGE,
} from 'src/constants/layout';

const DEFAULT_MAX_TEXT_COLUMNS_WIDTH_PX = 900;

const MAX_PORTRAIT_WIDTH_PX = 320;
const TEAM_MEMBER_SPACE_BETWEEN_PX = 32;

const TWO_PORTAITS_PER_ROW_BREAKPOINT = 500;
const THREE_PORTRAITS_PER_ROW_BREAKPOINT = 700;
const FOUR_PORTRAITS_PER_ROW_BREAKPOINT = 900;

const Styled = {
  HeroSection: styled.section`
    height: ${HERO_HEIGHTS_BY_PAGE.about.height};
    min-height: ${HERO_HEIGHTS_BY_PAGE.about['min-height']};
    max-height: ${HERO_HEIGHTS_BY_PAGE.about['max-height']};
    color: ${colors.black};
    position: relative;
  `,
  HeroContentWrapper: styled(StyledContentWrapper)`
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `,
  LogoImage: styled.img`
    margin-bottom: 2rem;
  `,
  BackgroundImage: styled(BackgroundImage)`
    min-height: 100%;
    background-position: bottom center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  H2: styled(H2)`
    margin-top: 0;
    display: inline-block;
  `,
  ContentWrapper: styled(StyledContentWrapper)`
    position: relative;
  `,
  SocialMediaContactInfo: styled.div`
    flex: 1;
    background: ${colors.grayBg1};
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: 0.002em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 5rem;
  `,
  Label: styled.div`
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.002em;
    width: 130px;
    min-width: 130px;
  `,
  ContactUsLinkButton: styled(Link)`
    text-decoration: none;
    color: ${colors.darkGray};
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    padding: 1.5rem 2.5rem;
    border: 2px solid ${colors.darkGray};
    margin-top: 2rem;
  `,
  Section1: styled.section`
    background: ${colors.grayBg4};
    width: 100%;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 50%;
      top: 0;
      bottom: 0;
      background: ${colors.darkBlue};
    }
    color: ${colors.white};
  `,
  OffsetContentWrapper: styled.div`
    text-align: right;
  `,
  OffsetContent: styled.div`
    padding: 5rem ${MOBILE_CONTENT_HZ_PADDING_PX}px 5rem 0;
    background: ${colors.darkBlue};
    display: inline-block;
    text-align: left;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      padding: 5rem ${CONTENT_HZ_PADDING_PX}px;
      padding-right: ${CONTENT_HZ_PADDING_PX * 2}px;
    }
  `,
  Section2: styled.section`
    background: ${colors.grayBg4};
    padding: 4rem 0;
  `,
  TextColumnsWrapper: styled.div`
    max-width: ${props =>
      props.maxWidth ? props.maxWidth : `${DEFAULT_MAX_TEXT_COLUMNS_WIDTH_PX}px`};
  `,
  TextColumns: styled.div`
    ${basePStyles}
    font-family: Open Sans;
    font-weight: 300;
    line-height: 27px;

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH_PX}px) {
      column-count: 2;
      column-gap: 1.5rem;
    }
  `,
  TeamSection: styled.section`
    background: ${colors.white};
    padding: 4rem 0;
  `,
  TeamPhotos: styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  `,
  TeamMember: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
    min-width: 100%;
    margin-top: ${TEAM_MEMBER_SPACE_BETWEEN_PX}px;
    &:first-child {
      margin-top: 0;
    }

    @media only screen and (min-width: ${TWO_PORTAITS_PER_ROW_BREAKPOINT}px) {
      text-align: left;
      min-width: calc(50% - ${TEAM_MEMBER_SPACE_BETWEEN_PX / 2}px);
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-right: ${TEAM_MEMBER_SPACE_BETWEEN_PX / 2}px;
      }
      &:nth-child(even) {
        margin-left: ${TEAM_MEMBER_SPACE_BETWEEN_PX / 2}px;
      }
    }

    @media only screen and (min-width: ${THREE_PORTRAITS_PER_ROW_BREAKPOINT}px) {
      min-width: calc(33.333% - (${(TEAM_MEMBER_SPACE_BETWEEN_PX * 2) / 3}px));
      max-width: calc(33.333% - (${(TEAM_MEMBER_SPACE_BETWEEN_PX * 2) / 3}px));
      &:nth-child(3) {
        margin-top: 0;
      }

      // unset margin styles from smaller media queries above
      &:nth-child(odd) {
        margin-right: unset;
      }
      &:nth-child(even) {
        margin-left: unset;
      }

      &:nth-child(3n + 2) {
        margin-left: ${TEAM_MEMBER_SPACE_BETWEEN_PX}px;
        margin-right: ${TEAM_MEMBER_SPACE_BETWEEN_PX}px;
      }
    }

    // UNCOMMENT LINES BELOW TO ENABLE 4 PORTRAITS PER ROW
    // @media only screen and (min-width: ${FOUR_PORTRAITS_PER_ROW_BREAKPOINT}px) {
    //   min-width: calc(25% - ${(TEAM_MEMBER_SPACE_BETWEEN_PX * 3) / 4}px);
    //   max-width: calc(25% - ${(TEAM_MEMBER_SPACE_BETWEEN_PX * 3) / 4}px);
    //   &:nth-child(4) {
    //     margin-top: 0;
    //   }

    //   // unset margin styles from smaller media queries above
    //   &:nth-child(3n + 2) {
    //     margin-left: unset;
    //     margin-right: unset;
    //   }

    //   &:nth-child(n) {
    //     margin-left: ${TEAM_MEMBER_SPACE_BETWEEN_PX}px;
    //   }
    //   &:nth-child(4n + 1) {
    //     margin-left: 0;
    //   }
    // }
  `,
  ImageWrapper: styled.div`
    width: 100%;
    max-width: ${MAX_PORTRAIT_WIDTH_PX}px;
    align-self: center;
  `,
  TeamMemberPortrait: styled(Img)`
    width: 100%;
  `,
  TeamMemberInfo: styled.div`
    font-family: Rubik;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    margin-top: 1rem;
    max-width: ${MAX_PORTRAIT_WIDTH_PX}px;
    align-self: center;
  `,
  TeamMemberName: styled.div`
    text-transform: uppercase;
  `,
  TeamMemberTitle: styled.div`
    font-style: italic;
    font-size: 1rem;
  `,
  TeamMemberBio: styled(P)``,
};

const TEAM_MEMBERS: {
  firstName: string;
  lastName: string;
  title: string;
  bio: string;
  imageId: string;
}[] = [
  {
    firstName: 'Mark',
    lastName: 'Spear',
    title: 'Founder',

    imageId: 'mark',
  },
  {
    firstName: 'Josh',
    lastName: 'Sigal',
    title: 'General Manager',

    imageId: 'josh',
  },
  {
    firstName: 'Will',
    lastName: 'Goldstein',
    title: 'Director of Engineering',

    imageId: 'will',
  },
  {
    firstName: 'Joey',
    lastName: 'Spear',
    title: 'Projects Manager',

    imageId: 'joey',
  },
  {
    firstName: 'Beverly',
    lastName: 'Ventura',
    title: 'Office Sales Supervisor',

    imageId: 'gabby',
  },
  {
    firstName: 'Jose',
    lastName: 'Guerra',
    title: 'Warehouse Operations Manager',

    imageId: 'jose',
  },
];

interface TeamMemberProps {
  firstName: string;
  lastName: string;
  title: string;
  bio: string;
  portraitImage: any;
}
const TeamMember: React.FC<TeamMemberProps> = ({
  firstName,
  lastName,
  title,
  bio,
  portraitImage,
}) => (
  <Styled.TeamMember>
    <Styled.ImageWrapper>
      <Styled.TeamMemberPortrait fluid={portraitImage} />
    </Styled.ImageWrapper>
    <Styled.TeamMemberInfo>
      <Styled.TeamMemberName>
        {firstName} {lastName}
      </Styled.TeamMemberName>
      <Styled.TeamMemberTitle>{title}</Styled.TeamMemberTitle>
      <Styled.TeamMemberBio>{bio}</Styled.TeamMemberBio>
    </Styled.TeamMemberInfo>
  </Styled.TeamMember>
);

const AboutPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "blur rack.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mark: file(relativePath: { eq: "mark-portrait-bw.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        josh: file(relativePath: { eq: "josh-portrait-bw.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        joey: file(relativePath: { eq: "joey-portrait-bw.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        jose: file(relativePath: { eq: "jose-portrait-bw.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        will: file(relativePath: { eq: "will-portrait-bw.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gabby: file(relativePath: { eq: "gabby-portrait-bw.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const heroImgData = data.hero.childImageSharp.fluid;

  const teamMembers = TEAM_MEMBERS.map(teamMember => ({
    ...omit(['imageId'], teamMember),
    portraitImage: data[teamMember.imageId].childImageSharp.fluid,
  }));

  return (
    <Layout page="about">
      <SEO title="About Us" />
      <Styled.HeroSection>
        <Styled.BackgroundImage Tag="section" fluid={heroImgData}>
          <Styled.HeroContentWrapper>
            <H1>A Little About Us</H1>
            <HeroSubtext>
              ATB Material Handling was started by industry veteran Mark Spear in
              1995
            </HeroSubtext>
            <Styled.ContactUsLinkButton to="/contact">
              Contact Us
            </Styled.ContactUsLinkButton>
          </Styled.HeroContentWrapper>
        </Styled.BackgroundImage>
      </Styled.HeroSection>
      <Styled.Section1>
        <Styled.ContentWrapper background={colors.darkBlue} vPadding="5rem">
          <Styled.OffsetContentWrapper>
            <Styled.OffsetContent>
              <Styled.H2>From one small yard to a national business</Styled.H2>
              <Styled.TextColumnsWrapper>
                <Styled.TextColumns>
                  ATB stands for Absolutely The Best. This is a personal, high
                  standard of professionalism which is foundational to the many years
                  of growth and great success of ATB. From a small parking lot
                  operation in the very beginning to now occupying and owning
                  multiple acres of outdoor and indoor space in the heart of Los
                  Angeles. ATB is on the call list of many American fortune 500
                  companies when they are in need of a prominent buyer for their
                  pallet rack and other warehouse equipment. ATB has quickly grown
                  into national prominence.
                </Styled.TextColumns>
              </Styled.TextColumnsWrapper>
            </Styled.OffsetContent>
          </Styled.OffsetContentWrapper>
        </Styled.ContentWrapper>
      </Styled.Section1>
      <Styled.Section2>
        <Styled.ContentWrapper>
          <Styled.H2>Character Counts</Styled.H2>
          <Styled.TextColumnsWrapper>
            <Styled.TextColumns>
              Reputation, for good or bad, is earned. It reflects character. ATB is
              very proud of our hard earned, long established, stellar reputation. No
              project is too large or small for our team. ATB is comprised of
              experienced material handling veterans with a deep knowledge of the
              products and industry. We go above and beyond for our customers.
            </Styled.TextColumns>
          </Styled.TextColumnsWrapper>
        </Styled.ContentWrapper>
      </Styled.Section2>

      <Styled.TeamSection>
        <Styled.ContentWrapper>
          <Styled.H2>Meet the Team</Styled.H2>
          <Styled.TeamPhotos>
            {teamMembers.map(teamMember => (
              <TeamMember
                key={`${teamMember.firstName}${teamMember.lastName}`}
                {...teamMember}
              />
            ))}
          </Styled.TeamPhotos>
        </Styled.ContentWrapper>
      </Styled.TeamSection>
    </Layout>
  );
};

export default AboutPage;
